import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem("token"),
    token2: sessionStorage.getItem("token2"),
  },
  getters: {
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      sessionStorage.setItem("token", token);
    },
    SET_TOKEN2: (state, token2) => {
      state.token2 = token2;
      sessionStorage.setItem("token2", token2);
    },
  },
  actions: {
  },
  modules: {
  }
})
