import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    redirect: "/map",
    children: [
      {
        path: '/map',
        name: 'map',
        component: () => import('../views/map/map.vue')
      },
      {
        path: '/carsScheduling',
        name: 'carsScheduling',
        component: () => import('../views/carsScheduling/index.vue')
      },{
        path: '/videos',
        name: 'videos',
        component: () => import('../views/videos/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = store.state.token||store.state.token2;
  if (to.name !== "login" && !token) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router
